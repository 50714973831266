import React from 'react';
import Scroll from '../components/Scroll';

function Careers() {
  return (
    <div>
      <Scroll/>
      <iframe width="640px" height="480px" src="https://forms.office.com/r/Xkb6aKR81L?embed=true" frameborder="0" marginwidth="0" marginheight="0"  style={{border:'none',maxWidth:'100vw',maxHeight:'100vh',minWidth:'100vw',minHeight:'100vh',marginTop:'7%'}} allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>
    </div>
  )
}

export default Careers;
