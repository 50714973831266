import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SocialWidget from '../Widget/SocialWidget';
import Newsletter from '../Widget/Newsletter';
import './header.scss';
import { Navigate } from 'react-router-dom';

import Div from '../Div';
import logo from './main-logo-white.png';
import { HashLink } from 'react-router-hash-link';
import GetInTouch from '../Widget/GetInTouch';


export default function Header({ variant }) {
  const navigate = useNavigate();

  const [isSticky, setIsSticky] = useState(false);
  const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeLink, setActiveLink] = useState('');
  const [arc, setarc] = useState(false)
  const [aboutus, setAboutus] = useState(false)
  const [tech, settech] = useState(false)
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
      if (window.scrollY === 0) {
        setarc(false)
        settech(false)
      }
    });
  }, []);

  return (
    <>
      <header
        className={`cs-site_header cs-style1 ${variant ? variant : ''
          } cs-sticky_header ${isSticky ? 'cs-sticky_header_active' : ''}`}
      >
        <div className="cs-main_header">
          <Div className="container">
            <Div className="cs-main_header_in">
              <div className='cs-site_branding_1'>
                <Link to="/">
                  <img src="/images/Logo-white.png" alt="Logo" onClick={scrollTop} />
                </Link>
              </div>
              <Div className="cs-main_header_center">
                <Div className="cs-nav cs-primary_font cs-medium">
                  <ul
                    className="cs-nav_list"
                    style={{ display: `${mobileToggle ? 'block' : 'none'}` }}
                  >
                    <li onClick={() => {
                      setarc(true)
                      settech(false)
                      setAboutus(false)
                    }} >
                      <HashLink to="/#Arc" onClick={() => handleLinkClick('tech')
                      }
                        className={arc === true ? 'active-link' : ''}>Arc</HashLink>
                    </li>
                    <li onClick={() => {
                      settech(true)
                      setarc(false)
                      setAboutus(false)
                    }}>
                      <HashLink onClick={() => handleLinkClick('tech')}
                        className={tech === true ? 'active-link' : ''} to="/#tech">Technology</HashLink>


                    </li>


                    {/* <li onClick={() => {
                      settech(true)
                      setarc(false)
                    }}>
                      <HashLink onClick={() => handleLinkClick('tech')}
                        className={tech === true ? 'active-link' : ''}>About US</HashLink>

                    </li> */}
                    <li
                      onClick={() => {
                        settech(false);
                        setarc(false);
                        setAboutus(true);
                        setShowDropdown(!showDropdown); 
                      }}
                      style={{ position: 'relative' }}
                    >
                      <HashLink
                        onClick={() => handleLinkClick('aboutus')}
                        className={aboutus === true ? 'active-link' : ''}
                      >
                        About Us
                      </HashLink>

                      <ul
                        style={{
                          // position: 'absolute',
                          // display: `${mobileToggle ? 'relative' : 'absolute'}`,
                          position: mobileToggle ? 'relative' : 'absolute', 
                          top: '100%',
                          left: '0',
                      
                          display: showDropdown ? 'block' : 'none',
                          backgroundColor: '#181818',
                          color: '#fff',
                          padding: '0px 20px',
                          listStyle: 'none',
                          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0)',
                          zIndex: 10,
                        }}
                        className="dropdown-menu"
                      >
                        <li style={{ padding: '5px 0', cursor: "pointer" }} onClick={() => {
                          setShowDropdown(false);
                          navigate('/internship');
                        }}> Internship

                        </li>
                      </ul>
                    </li>



                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? 'cs-munu_toggle cs-toggle_active'
                        : 'cs-munu_toggle'
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </Div>
              </Div>
              <Div className="cs-main_header_right">
                <Div className="cs-toolbox">
                  <span
                    className="cs-icon_btn"
                    onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
                  >
                    <span className="cs-icon_btn_in">
                      <span />
                      <span />
                      <span />
                      <span />
                    </span>
                    <div className='logo-container'>
                      <img src={logo} alt='syra-logo'></img>
                    </div>
                  </span>
                </Div>
              </Div>
            </Div>
          </Div>
        </div>
      </header>

      <Div
        className={
          sideHeaderToggle ? 'cs-side_header active' : 'cs-side_header'
        }
      >
        <button
          className="cs-close"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <Div
          className="cs-side_header_overlay"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <Div className="cs-side_header_in">
          <Div className="cs-side_header_shape" />
          <Link className="cs-site_branding" to="/" >
            <img src="/images/Logo-white.png" alt="Logo" style={{ translate: '-10% -20% 0' }} />
          </Link>
          <Div className="cs-side_header_box">
            <h2 className="cs-side_header_heading">
              For Everyone !
            </h2>
          </Div>
          <Div className="cs-side_header_box">
            {/* <ContactInfoWidget title="Get in Touch" withIcon /> */}
            <GetInTouch title="Get in Touch" underline={true} />
          </Div>
          <Div className="cs-side_header_box">
            <Newsletter
              title="Subscribe"
              subtitle="Subscribe now to receive the latest updates, special offers, and exclusive content straight to your inbox."
              placeholder="Your email Here"
            />
          </Div>
          <Div className="cs-side_header_box">
            <SocialWidget />
          </Div>
        </Div>
      </Div>
    </>
  );
}
