import { Route, Routes } from 'react-router-dom';
import Privacy from './components/PrivacyPolicy';
import Home from './components/Pages/Home';
import Layout from './components/Layout';
// import Accordion from './Accordion/Accordion';
import TermsOfUse from './components/TermsOfUse/Terms';
import Arc from './components/Arc/Arc';
import Careers from './Careers/Careers';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout></Layout>}>
        <Route  index element={<Home />} />
          <Route path='/internship' element={<Careers></Careers>}/>
          <Route path='home' element={<Home></Home>}></Route>
          <Route path="privacy-policy" element={<Privacy/>}/>
          <Route path="terms-of-use" element={<TermsOfUse/>}/>
          <Route path="Arc" element={<Arc />} />
          {/* <Route path="faq"element={<Accordion/>}/> */}
        </Route>
        <Route
          path="/"
          element={<Layout headerVariant="cs-site_header_full_width" />}
        >
        </Route>
      </Routes>
    </>
  );
}

export default App;
